import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CandidateFormService } from '@pages/candidates/services/base/candidate-form.service';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { forkJoin, merge } from 'rxjs';
import { AppStateService } from '@shared/services/app-state.service';
import { debounceTime, distinctUntilChanged, startWith, take } from 'rxjs/operators';
import { Source } from '@pages/candidates/classes/Source';
import { JobType } from '@pages/candidates/classes/JobType';
import { Country } from '@shared/classes/Country';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Language } from '@pages/candidates/classes/Language';
import * as moment from 'moment';
import { checkOlderOrEqualThan18 } from '@pages/candidates/utils/check-older-than-18.util';
import { CandidatesService } from '@pages/candidates/services/candidates.service';
import { City } from '@shared/classes/City';
import { EducationType } from '@pages/positions/classes/EducationType';
import { ProviderType } from '@pages/candidates/classes/ProviderType';
import { ATSConfigService } from '@shared/services/ats-config.service';
import { AppConstants } from '@config/app.constant';
import { EmailValidatorResponse } from '@pages/users/classes/EmailValidatorResponse';
import { AppConfig } from '@config/app.config';
import { PositionsApiService } from '@pages/positions/services/details/base/positions-api.service';
import { PartnerDropdownItem } from '@pages/partners/classes/PartnerDropdownItem';
import { Contact } from '@pages/partners/classes/Contact';
import { MatModalBaseComponent } from '@shared/modules/mat-modal/mat-modal-base.component';
import { CandidateStateService } from '@pages/candidates/services/base/candidate-state.service';
import { CandidateDto, CandidateFormDto } from '@pages/candidates/classes/CandidateDto';
import { CandidateCustomField } from '@pages/candidates/classes/CandidateCustomField';
import { CandidateApiService } from '@pages/candidates/services/base/candidate-api.service';
import { ManageCandidateModalData } from '@shared/modules/modals/candidate-modals/manage-candidate-modal/config/ManageCandidateModalConfig';
import { PhoneContactsService } from '@pages/phone-contacts/services/phone-contacts.service';
import { PhoneContactStateService } from '@pages/phone-contacts/services/base/phone-contact-state.service';
import { CandidateDetail } from '@pages/candidates/classes/CandidateDetail';
import { setValidationByModalType } from '@shared/utils/form/set-validation-by-modal.type.util';
import { ContactType } from '@shared/classes/ContactType';
import { ModalType } from '@shared/classes/ModalType';
import { TranslateInstance } from '@shared/utils/TranslateInstance';
import { getModalTypeUtil } from '@pages/candidates/utils/get-modal-type.util';
import { CandidateParent } from '@pages/candidates/classes/CandidateParent';
import { LanguageLevel } from '@pages/candidates/classes/LanguageLevel';

@UntilDestroy()
@Component({
  selector: 'app-manage-candidate-modal',
  templateUrl: './manage-candidate-modal.component.html',
  styleUrls: ['./manage-candidate-modal.component.scss'],
})
export class ManageCandidateModalComponent
  extends MatModalBaseComponent<ManageCandidateModalData>
  implements OnInit, OnDestroy {
  form: FormGroup;
  parentsGroup: FormGroup;
  parentsArray: FormArray;
  addressGroup: FormGroup;
  cityPreferenceGroup: FormGroup;
  languagesArray: FormArray;
  customFieldsArray: FormArray;
  providerType: FormGroup;
  birthDateMaxDate: Date;
  membershipMinDate = new Date();
  membershipMaxDate = new Date();
  contactUserId: number;

  sources: Source[] = [];
  jobTypes: JobType[] = [];
  countries: Country[] = [];
  languages: Language[] = [];
  preferredLanguages: Language[] = [];
  languageLevels: LanguageLevel[] = [];
  educationTypes: EducationType[] = [];
  partners: PartnerDropdownItem[] = [];
  projectManagers: Contact[] = [];
  customFields: CandidateCustomField[] = [];

  isOlderOrEqualThan18 = false;
  livingCity: City;
  preferredCity: City;
  createdAtPositions: boolean;
  isCreateMode = false;
  isLaborHireATS = false;
  isStudentATS = false;
  isPensionerATS = false;
  isContactTypeControlValid = false;
  candidateEmailAlreadyExistsResponse: EmailValidatorResponse;

  modalTitle: string;
  modalType: ModalType;

  readonly MODAL_TYPES = ModalType;
  readonly CONTACT_TYPES: ContactType[] = [
    {
      name: TranslateInstance.instant('phone_contacts.contact_types.phone_contact'),
      value: ModalType.PhoneContact,
    },
    {
      name: TranslateInstance.instant('phone_contacts.contact_types.user_contact'),
      value: ModalType.UserContact,
    },
  ];

  readonly CONTACT_IGNORE_CHECK_FIELDS = [
    'providerType',
    'contactType',
    'membershipStartDate',
    'membershipEndDate',
  ];

  constructor(
    private candidateFormService: CandidateFormService,
    private appService: AppStateService,
    private candidateState: CandidateStateService,
    private candidateService: CandidatesService,
    private positionApiService: PositionsApiService,
    private atsConfigService: ATSConfigService,
    private candidateApiService: CandidateApiService,
    private phoneContactService: PhoneContactsService,
    private phoneContactState: PhoneContactStateService,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loading = true;
    this.modalType = this.data?.type;

    this.isLaborHireATS = this.candidateFormService.atsConfigService.isLaborHireATS;
    this.isStudentATS = this.candidateFormService.atsConfigService.isStudentATS;
    this.isPensionerATS = this.candidateFormService.atsConfigService.isPensionerATS;

    this.loadDependencies();
  }

  private loadDependencies() {
    const candidate = this.candidateState.getStateSnapshot().candidateDetail;

    if (candidate) {
      this.modalType = getModalTypeUtil(candidate.roles);
    }

    this.setEditModeAndModalTitle(candidate);

    const baseDependencies = {
      sources: this.appService.getSources(true).pipe(take(1)),
      countries: this.appService.getCountries().pipe(take(1)),
      partners: this.positionApiService.getPartnersForDropdown().pipe(take(1)),
      languages: this.appService.getLanguages().pipe(take(1)),
      customFields: this.candidateApiService.getCandidateCustomFields().pipe(take(1)),
      projectManagers: this.appService.getProjectManagers().pipe(take(1)),
    };

    const studentAndPensionerDependencies = {
      languageLevels: this.appService.getLanguageLevels().pipe(take(1)),
      jobTypes: this.appService.getJobTypes().pipe(take(1)),
      specializations: this.appService.getSpecializations().pipe(take(1)),
    };

    const mergedDependencies = { ...baseDependencies, ...studentAndPensionerDependencies };

    forkJoin(mergedDependencies)
      .pipe(untilDestroyed(this))
      .subscribe(
        ({
          sources,
          jobTypes,
          countries,
          languages,
          languageLevels,
          partners,
          projectManagers,
          customFields,
        }) => {
          this.birthDateMaxDate = this.getBirthDateMaxDate();
          this.sources = sources;
          this.jobTypes = jobTypes;
          this.countries = countries;
          this.languages = languages;
          this.preferredLanguages = this.languages.filter(
            (language: Language) => language.isPreferable
          );
          this.languageLevels = languageLevels;
          this.projectManagers = projectManagers;
          this.partners = partners;
          this.customFields = customFields;

          this.livingCity = candidate?.profile?.address?.city;
          this.preferredCity = Array.isArray(candidate?.cityPreferences)
            ? candidate.cityPreferences[0]
            : null;
          this.createdAtPositions = this.candidateState.getStateSnapshot().candidateModalOpenedFromPosition;

          if (this.isCreateMode && candidate?.profile?.providerType) {
            this.addSourceIfNotExists(candidate.profile.providerType);
          }

          this.form = this.candidateFormService.initCandidateForm(this.modalType, candidate);

          if (
            this.isCreateMode &&
            [ModalType.PhoneContact, ModalType.UserContact].includes(this.modalType)
          ) {
            this.updateFormControlsDisabled();
            this.listenContactTypeChanges();
          }

          this.languagesArray = this.form.get('languages') as FormArray;
          this.customFieldsArray = this.form.get('profile.customFields') as FormArray;
          this.parentsGroup = (this.form.get('profile.parents') as FormArray).at(0) as FormGroup;
          this.parentsArray = this.form.get('profile.parents') as FormArray;
          this.cityPreferenceGroup = (this.form.get('cityPreferences') as FormArray).at(
            0
          ) as FormGroup;
          this.addressGroup = this.form.get('profile.address') as FormGroup;
          this.providerType = this.form.get('profile.providerType') as FormGroup;

          if (this.isLaborHireATS) {
            this.contactUserId = this.form.get('profile.contactUserId').value as number;
          }

          this.listenToEmailValidity();
          this.listenBirthDateChanges();

          this.primaryButtonDisabled = this.form.invalid;
          this.loading = false;

          this.listenFormValueChanges();
        }
      );
  }

  private updateFormControlsDisabled() {
    this.checkFormElements(this.form, this.CONTACT_IGNORE_CHECK_FIELDS);
  }

  private checkFormElements(
    formGroup: FormGroup | AbstractControl,
    ignoreCheckForControlKeys: string[]
  ): void {
    this.isContactTypeControlValid = this.form.get('contactType').valid;

    if (formGroup instanceof FormGroup || formGroup instanceof FormArray) {
      Object.keys(formGroup.controls).forEach((controlName) => {
        const control = formGroup.get(controlName as string);

        if (!ignoreCheckForControlKeys.includes(controlName as string)) {
          if (control instanceof FormArray || control instanceof FormGroup) {
            this.checkFormElements(control, ignoreCheckForControlKeys);
          } else if (control instanceof FormControl) {
            this.applyValidators(control, this.modalType, controlName as string);

            control[this.isContactTypeControlValid ? 'enable' : 'disable']();
          }
        }
      });
    }
  }

  listenFormValueChanges() {
    merge(this.form.valueChanges, this.form.statusChanges)
      .pipe(startWith(this.form.getRawValue()), untilDestroyed(this))
      .subscribe(() => {
        this.setMembershipDates();
        let isFormInvalid = this.form.invalid;
        if (this.form.status === 'PENDING') {
          isFormInvalid = true;
        }

        this.primaryButtonDisabled = isFormInvalid;
      });
  }

  private addSourceIfNotExists(providerType: ProviderType): void {
    const existingSource = this.sources.find((source) => source.id === providerType.id);

    if (!existingSource) {
      this.sources.push({
        id: providerType.id as number,
        name: providerType.name,
      });
    }
  }

  onProviderSelected(provider: Source) {
    this.form.get('profile.providerType.name').setValue(provider.name);
  }

  goToCandidate(): void {
    window.open(
      `${AppConfig.candidatesUrl}/${this.candidateEmailAlreadyExistsResponse.userId}`,
      '_blank'
    );
  }

  private setEditModeAndModalTitle(candidate: CandidateDetail): void {
    this.isCreateMode = !candidate;
    this.candidateState.setState({ isEditMode: !this.isCreateMode });

    switch (this.modalType) {
      case ModalType.Candidate:
        this.modalTitle = this.isCreateMode
          ? 'candidates.crate_candidate'
          : 'candidates.edit_candidate';
        break;

      case ModalType.PhoneContact:
      case ModalType.UserContact:
        this.modalTitle = this.isCreateMode
          ? 'phone_contacts.modal.create_phone_contact'
          : 'phone_contacts.modal.edit_phone_contact';
        break;

      //TODO: It is not needed at the moment, but may be needed later.
      /*case UserRole.PositionApplication:
        this.modalTitle = !this.isEditMode
            ? 'position_applications.modal.create_position_application'
            : 'position_applications.modal.edit_position_application';
        break;*/
    }
  }

  private foundProviderType(sources: Source[]) {
    const contactTypeValue = this.form.get('contactType').value as ModalType;
    const searchName = this.CONTACT_TYPES.find((type) => type.value === contactTypeValue)?.name;
    const foundProvider = sources.find((source) => source.name === searchName);

    this.phoneContactState.setState({ providerTypeId: foundProvider?.id });

    return foundProvider?.id;
  }

  private getBirthDateMaxDate(): Date {
    return this.atsConfigService.isStudentATS && this.modalType !== ModalType.PhoneContact
      ? new Date()
      : moment(new Date()).subtract(AppConstants.ageLimit, 'y').subtract(1, 'd').toDate();
  }

  private setMembershipDates(): void {
    const start = this.form.get('profile.membershipStartDate').value as string;
    const end = this.form.get('profile.membershipEndDate').value as string;

    if (start) {
      this.membershipMinDate = moment(new Date(start)).add(1, 'day').toDate();
    }

    if (end) {
      this.membershipMaxDate = moment(new Date(end)).subtract(1, 'day').toDate();
    }
  }

  private listenContactTypeChanges(): void {
    const contactTypeControl = this.form.get('contactType') as FormControl;

    contactTypeControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.modalType = value as ModalType;
      this.birthDateMaxDate = this.getBirthDateMaxDate();

      this.updateFormControlsDisabled();

      this.providerType?.get('id').setValue(this.foundProviderType(this.sources));
    });
  }

  private applyValidators(control: FormControl, modalType: ModalType, controlName: string): void {
    control.removeValidators(Validators.required);

    const existingValidators = control.validator;
    const validators = setValidationByModalType(controlName, modalType, this.isStudentATS);

    control.clearValidators();

    if (existingValidators) {
      control.setValidators([existingValidators, ...validators]);
    } else {
      control.setValidators(validators);
    }

    control.updateValueAndValidity();
  }

  private listenBirthDateChanges(): void {
    const birthDateControl = this.form.get('profile.birthDate') as FormControl;
    birthDateControl.valueChanges
      .pipe(startWith(birthDateControl.value), untilDestroyed(this))
      .subscribe((birthDate: moment.Moment | string) => {
        const date = typeof birthDate === 'string' ? birthDate : birthDate?.toISOString();
        const parentGroup = this.candidateFormService.getParentGroup();
        this.isOlderOrEqualThan18 = checkOlderOrEqualThan18(date);

        if (this.isOlderOrEqualThan18) {
          this.parentsArray.removeAt(0);
        } else if (this.parentsArray.length === 0) {
          this.parentsArray.push(parentGroup);
        } else if (!this.isOlderOrEqualThan18 && birthDate) {
          this.updateValidatorsForControls(this.parentsArray.controls, Validators.required);
        }

        this.form.updateValueAndValidity();
      });
  }

  private updateValidatorsForControls(
    controls: AbstractControl[],
    validators: ValidatorFn | ValidatorFn[]
  ): void {
    controls.forEach((control) => {
      if (control instanceof FormGroup) {
        Object.keys(control.controls).forEach((controlName) => {
          control.get(controlName as string)?.setValidators(validators);
          control.get(controlName as string)?.updateValueAndValidity();
        });
      }
    });
  }

  private listenToEmailValidity(): void {
    this.form
      .get('email')
      .statusChanges.pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        const emailError = this.form.get('email').errors;

        this.candidateEmailAlreadyExistsResponse = emailError?.isExists
          ? (emailError as EmailValidatorResponse)
          : null;
      });
  }

  updateCandidate(): void {
    this.primaryButtonDisabled = true;
    const dto = this.mapFormToCandidate(this.form.getRawValue() as CandidateFormDto);

    if (this.modalType === ModalType.Candidate) {
      if (this.isCreateMode) {
        this.candidateService
          .callCreateCandidate(dto, this.form)
          .subscribe(this.handleConfirmActionResponse.bind(this));
      } else {
        this.candidateService
          .callUpdateCandidate(dto, this.form)
          .subscribe(this.handleConfirmActionResponse.bind(this));
      }
    } else if (
      this.modalType === ModalType.PhoneContact ||
      this.modalType === ModalType.UserContact
    ) {
      if (this.isCreateMode) {
        this.phoneContactService
          .callCreatePhoneContact(dto, this.form)
          .subscribe(this.handleConfirmActionResponse.bind(this));
      } else {
        this.phoneContactService
          .callUpdatePhoneContact(dto, this.form)
          .subscribe(this.handleConfirmActionResponse.bind(this));
      }
    }
  }

  private mapFormToCandidate(formDto: CandidateFormDto): CandidateDto {
    const candidateDto: CandidateDto = { ...formDto };

    if (formDto.profile.parents) {
      const parents: CandidateParent[] = [];

      for (const parent of formDto.profile.parents) {
        if (parent.parentEmail) {
          parents.push({
            firstName: parent.parentFirstName,
            lastName: parent.parentLastName,
            email: parent.parentEmail,
          });
        }
      }

      candidateDto.profile.parents = parents;
    }

    return candidateDto;
  }

  ngOnDestroy() {
    if (this.isCreateMode) {
      this.phoneContactState.setState({ providerTypeId: null });
    }
    return super.ngOnDestroy();
  }
}
